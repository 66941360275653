import { render, staticRenderFns } from "./templateManage.vue?vue&type=template&id=fb3e0454&scoped=true&"
import script from "./templateManage.vue?vue&type=script&lang=ts&"
export * from "./templateManage.vue?vue&type=script&lang=ts&"
import style0 from "./templateManage.vue?vue&type=style&index=0&id=fb3e0454&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb3e0454",
  null
  
)

export default component.exports